exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ab-testing-guide-js": () => import("./../../../src/pages/ab-testing-guide.js" /* webpackChunkName: "component---src-pages-ab-testing-guide-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-ai-and-ml-js": () => import("./../../../src/pages/ai-and-ml.js" /* webpackChunkName: "component---src-pages-ai-and-ml-js" */),
  "component---src-pages-benefits-outsourced-qa-service-js": () => import("./../../../src/pages/benefits-outsourced-qa-service.js" /* webpackChunkName: "component---src-pages-benefits-outsourced-qa-service-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-branding-posts-js": () => import("./../../../src/pages/branding-posts.js" /* webpackChunkName: "component---src-pages-branding-posts-js" */),
  "component---src-pages-essential-steps-choosing-tech-business-website-js": () => import("./../../../src/pages/essential-steps-choosing-tech-business-website.js" /* webpackChunkName: "component---src-pages-essential-steps-choosing-tech-business-website-js" */),
  "component---src-pages-future-of-marketing-30-second-videos-js": () => import("./../../../src/pages/future-of-marketing-30-second-videos.js" /* webpackChunkName: "component---src-pages-future-of-marketing-30-second-videos-js" */),
  "component---src-pages-graphic-design-js": () => import("./../../../src/pages/graphic-design.js" /* webpackChunkName: "component---src-pages-graphic-design-js" */),
  "component---src-pages-graphic-design-posts-js": () => import("./../../../src/pages/graphic-design-posts.js" /* webpackChunkName: "component---src-pages-graphic-design-posts-js" */),
  "component---src-pages-hire-design-agency-over-designer-js": () => import("./../../../src/pages/hire-design-agency-over-designer.js" /* webpackChunkName: "component---src-pages-hire-design-agency-over-designer-js" */),
  "component---src-pages-hire-from-srilanka-js": () => import("./../../../src/pages/hire-from-srilanka.js" /* webpackChunkName: "component---src-pages-hire-from-srilanka-js" */),
  "component---src-pages-how-to-build-social-media-strategy-js": () => import("./../../../src/pages/how-to-build-social-media-strategy.js" /* webpackChunkName: "component---src-pages-how-to-build-social-media-strategy-js" */),
  "component---src-pages-how-to-choose-the-best-web-designer-or-web-development-service-js": () => import("./../../../src/pages/how-to-choose-the-best-web-designer-or-web-development-service.js" /* webpackChunkName: "component---src-pages-how-to-choose-the-best-web-designer-or-web-development-service-js" */),
  "component---src-pages-how-to-create-a-memorable-brand-js": () => import("./../../../src/pages/how-to-create-a-memorable-brand.js" /* webpackChunkName: "component---src-pages-how-to-create-a-memorable-brand-js" */),
  "component---src-pages-how-to-identify-your-target-audience-js": () => import("./../../../src/pages/how-to-identify-your-target-audience.js" /* webpackChunkName: "component---src-pages-how-to-identify-your-target-audience-js" */),
  "component---src-pages-ideachat-ai-js": () => import("./../../../src/pages/ideachat-ai.js" /* webpackChunkName: "component---src-pages-ideachat-ai-js" */),
  "component---src-pages-importance-of-proper-website-ui-before-development-js": () => import("./../../../src/pages/importance-of-proper-website-ui-before-development.js" /* webpackChunkName: "component---src-pages-importance-of-proper-website-ui-before-development-js" */),
  "component---src-pages-inbound-marketing-guide-js": () => import("./../../../src/pages/inbound-marketing-guide.js" /* webpackChunkName: "component---src-pages-inbound-marketing-guide-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-launch-successful-linkedin-ad-campaign-js": () => import("./../../../src/pages/launch-successful-linkedin-ad-campaign.js" /* webpackChunkName: "component---src-pages-launch-successful-linkedin-ad-campaign-js" */),
  "component---src-pages-machine-learning-posts-js": () => import("./../../../src/pages/machine-learning-posts.js" /* webpackChunkName: "component---src-pages-machine-learning-posts-js" */),
  "component---src-pages-mobile-app-posts-js": () => import("./../../../src/pages/mobile-app-posts.js" /* webpackChunkName: "component---src-pages-mobile-app-posts-js" */),
  "component---src-pages-neurox-js": () => import("./../../../src/pages/neurox.js" /* webpackChunkName: "component---src-pages-neurox-js" */),
  "component---src-pages-onsite-seo-vs-offsite-seo-js": () => import("./../../../src/pages/onsite-seo-vs-offsite-seo.js" /* webpackChunkName: "component---src-pages-onsite-seo-vs-offsite-seo-js" */),
  "component---src-pages-penetration-testing-vs-qa-testing-services-js": () => import("./../../../src/pages/penetration-testing-vs-qa-testing-services.js" /* webpackChunkName: "component---src-pages-penetration-testing-vs-qa-testing-services-js" */),
  "component---src-pages-print-media-guide-js": () => import("./../../../src/pages/print-media-guide.js" /* webpackChunkName: "component---src-pages-print-media-guide-js" */),
  "component---src-pages-react-vs-wordpress-js": () => import("./../../../src/pages/react-vs-wordpress.js" /* webpackChunkName: "component---src-pages-react-vs-wordpress-js" */),
  "component---src-pages-secret-success-ecommerce-js": () => import("./../../../src/pages/secret-success-ecommerce.js" /* webpackChunkName: "component---src-pages-secret-success-ecommerce-js" */),
  "component---src-pages-seo-posts-js": () => import("./../../../src/pages/seo-posts.js" /* webpackChunkName: "component---src-pages-seo-posts-js" */),
  "component---src-pages-social-media-brand-kit-js": () => import("./../../../src/pages/social-media-brand-kit.js" /* webpackChunkName: "component---src-pages-social-media-brand-kit-js" */),
  "component---src-pages-social-media-for-business-24-js": () => import("./../../../src/pages/social-media-for-business-24.js" /* webpackChunkName: "component---src-pages-social-media-for-business-24-js" */),
  "component---src-pages-social-media-marketing-posts-js": () => import("./../../../src/pages/social-media-marketing-posts.js" /* webpackChunkName: "component---src-pages-social-media-marketing-posts-js" */),
  "component---src-pages-software-quality-assurance-js": () => import("./../../../src/pages/software-quality-assurance.js" /* webpackChunkName: "component---src-pages-software-quality-assurance-js" */),
  "component---src-pages-software-testing-best-practices-js": () => import("./../../../src/pages/software-testing-best-practices.js" /* webpackChunkName: "component---src-pages-software-testing-best-practices-js" */),
  "component---src-pages-steps-to-build-organic-growth-social-media-js": () => import("./../../../src/pages/steps-to-build-organic-growth-social-media.js" /* webpackChunkName: "component---src-pages-steps-to-build-organic-growth-social-media-js" */),
  "component---src-pages-steps-to-take-before-hiring-a-logo-designer-js": () => import("./../../../src/pages/steps-to-take-before-hiring-a-logo-designer.js" /* webpackChunkName: "component---src-pages-steps-to-take-before-hiring-a-logo-designer-js" */),
  "component---src-pages-testing-posts-js": () => import("./../../../src/pages/testing-posts.js" /* webpackChunkName: "component---src-pages-testing-posts-js" */),
  "component---src-pages-time-to-ux-revamp-js": () => import("./../../../src/pages/time-to-ux-revamp.js" /* webpackChunkName: "component---src-pages-time-to-ux-revamp-js" */),
  "component---src-pages-ui-ux-audit-for-your-digital-solution-js": () => import("./../../../src/pages/ui-ux-audit-for-your-digital-solution.js" /* webpackChunkName: "component---src-pages-ui-ux-audit-for-your-digital-solution-js" */),
  "component---src-pages-ui-ux-js": () => import("./../../../src/pages/ui-ux.js" /* webpackChunkName: "component---src-pages-ui-ux-js" */),
  "component---src-pages-ui-ux-posts-js": () => import("./../../../src/pages/ui-ux-posts.js" /* webpackChunkName: "component---src-pages-ui-ux-posts-js" */),
  "component---src-pages-using-google-analytics-for-seo-comprehensive-guide-js": () => import("./../../../src/pages/using-google-analytics-for-seo-comprehensive-guide.js" /* webpackChunkName: "component---src-pages-using-google-analytics-for-seo-comprehensive-guide-js" */),
  "component---src-pages-web-dev-js": () => import("./../../../src/pages/web-dev.js" /* webpackChunkName: "component---src-pages-web-dev-js" */),
  "component---src-pages-web-dev-posts-js": () => import("./../../../src/pages/web-dev-posts.js" /* webpackChunkName: "component---src-pages-web-dev-posts-js" */),
  "component---src-pages-what-is-a-robot-txt-js": () => import("./../../../src/pages/what-is-a-robot-txt.js" /* webpackChunkName: "component---src-pages-what-is-a-robot-txt-js" */),
  "component---src-pages-what-is-a-web-app-js": () => import("./../../../src/pages/what-is-a-web-app.js" /* webpackChunkName: "component---src-pages-what-is-a-web-app-js" */),
  "component---src-pages-what-is-a-web-hosting-js": () => import("./../../../src/pages/what-is-a-web-hosting.js" /* webpackChunkName: "component---src-pages-what-is-a-web-hosting-js" */),
  "component---src-pages-what-is-branding-js": () => import("./../../../src/pages/what-is-branding.js" /* webpackChunkName: "component---src-pages-what-is-branding-js" */),
  "component---src-pages-what-is-ml-js": () => import("./../../../src/pages/what-is-ml.js" /* webpackChunkName: "component---src-pages-what-is-ml-js" */),
  "component---src-pages-what-is-ppc-marketing-js": () => import("./../../../src/pages/what-is-ppc-marketing.js" /* webpackChunkName: "component---src-pages-what-is-ppc-marketing-js" */),
  "component---src-pages-what-is-seo-js": () => import("./../../../src/pages/what-is-seo.js" /* webpackChunkName: "component---src-pages-what-is-seo-js" */),
  "component---src-pages-why-instagram-best-for-clothing-brand-js": () => import("./../../../src/pages/why-instagram-best-for-clothing-brand.js" /* webpackChunkName: "component---src-pages-why-instagram-best-for-clothing-brand-js" */),
  "component---src-pages-why-you-need-a-mascot-js": () => import("./../../../src/pages/why-you-need-a-mascot.js" /* webpackChunkName: "component---src-pages-why-you-need-a-mascot-js" */),
  "component---src-pages-why-you-need-an-ai-chat-bot-to-boost-your-business-js": () => import("./../../../src/pages/why-you-need-an-ai-chat-bot-to-boost-your-business.js" /* webpackChunkName: "component---src-pages-why-you-need-an-ai-chat-bot-to-boost-your-business-js" */),
  "component---src-pages-why-you-need-an-animated-email-signature-js": () => import("./../../../src/pages/why-you-need-an-animated-email-signature.js" /* webpackChunkName: "component---src-pages-why-you-need-an-animated-email-signature-js" */),
  "component---src-pages-why-you-still-need-a-real-designer-for-your-design-needs-js": () => import("./../../../src/pages/why-you-still-need-a-real-designer-for-your-design-needs.js" /* webpackChunkName: "component---src-pages-why-you-still-need-a-real-designer-for-your-design-needs-js" */),
  "component---src-pages-why-your-business-needs-a-mobile-app-js": () => import("./../../../src/pages/why-your-business-needs-a-mobile-app.js" /* webpackChunkName: "component---src-pages-why-your-business-needs-a-mobile-app-js" */),
  "component---src-pages-why-your-business-needs-its-own-rag-enhancing-efficiency-with-ml-js": () => import("./../../../src/pages/why-your-business-needs-its-own-rag-enhancing-efficiency-with-ml.js" /* webpackChunkName: "component---src-pages-why-your-business-needs-its-own-rag-enhancing-efficiency-with-ml-js" */)
}

