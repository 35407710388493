import "bootstrap/dist/js/bootstrap.min.js";
import "@popperjs/core/dist/umd/popper.min.js";
import browserHydrateFunction from './src/utils/browser-hydrate-function';

export const onInitialClientRender = () => {
    setTimeout(function() {
        document.getElementById("___loader").style.display = "none"
    }, 1000)
}

export const replaceHydrateFunction = browserHydrateFunction;